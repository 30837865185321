.sidebar {
  background: linear-gradient(180deg, #550196 0%, #a05fe3 100%);

  hr {
    color: rgba($white, 0.5);
    margin: 1.5rem 0;
  }

  &__logo {
    img {
      width: 100%;
      max-width: 160px;
    }
  }
}

@include media-breakpoint-down(md) {
  .sidebar {
    &__logo {
      img {
        max-height: 65px;
      }
    }

    .nav-link {
      text-align: center;
    }
  }
}

@include media-breakpoint-up(md) {
  .sidebar {
    width: 270px;
  }
}
