.form-label {
  @extend .label-1;
  @extend .font-regular;

  color: $gray-900;
}

.form-control {
  padding: 0.575rem 0.75rem;
  border-radius: 10px;

  color: $body-color;

  &.shadow {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  }

  &::placeholder {
    @extend .font-light;

    color: $gray-600;
    font-size: 0.875rem;
  }

  &:focus {
    border-color: $secondary;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.03), 0 0 8px rgba($secondary, 0.4) !important;
  }
}

.input-group {
  &-text {
    border-radius: 10px;
    font-size: 0.875rem;
  }
}

.form-switch .form-check-input {
  background-color: #cacaca;
  border: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");

  &:checked {
    background-color: $secondary;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
  }
}

.input-group {
  &-text {
    border-radius: 10px;
    font-size: 0.875rem;
  }
}

.form-switch .form-check-input {
  background-color: #cacaca;
  border: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");

  &:checked {
    background-color: $secondary;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
  }
}

.btn.btn-icon {
  background-color: transparent;
  border: none;
  padding: 12px;
  cursor: pointer;
  color: var(--color-primary);
}

.btn.btn.btn-icon:hover {
  background-color: #fff;
  border: none;
}

.disabled-input{
  color: #c3c3c3 !important
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}