.user-progress {
  &__badge {
    zoom: 0.75;
  }

  &__info {
    font-size: 0.875rem;
  }

  .dark & {
    &__item span {
      color: rgba(255, 255, 255, 0.7);
    }

    &__icon {
      background: rgba(255, 255, 255, 0.4);
      border-color: rgba(218, 228, 237, 0.5);
    }
  }
}

.progress {
  height: 0.75rem;
  background-color: #dae4ed;
  &-bar {
    &.bg-green {
      background-color: #3ec23b;
    }

    &.bg-orange {
      background-color: #f46e49;
    }

    &.bg-yellow {
      background-color: #fcdb31;
    }
  }
}
