.tourTooltip {
  &.reactour__helper {
    background-color: $white;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: 1rem;
    background-clip: padding-box;
    color: var(--bs-modal-color);

    p {
      color: #6F6F6F;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
