* {
  outline: none;
}

body {
  background-color: $body-bg;

  @extend .body1-regular;

  color: $body-color;
}
