.socials-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  &__item {
    padding: 0 1rem;
    text-align: center;
    margin-bottom: 1rem;

    &-title {
      color: $gray-600;
      margin-top: 1.5rem;
      font-size: 0.875rem;
    }

    .btn-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      min-width: 45px;
      min-height: 45px;

      background-color: $white !important;
      border: 1px solid $gray-400 !important;

      i {
        font-size: 1.75rem;
      }

      &:hover {
        background-color: rgba($secondary, 0.1);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .socials-list__item {
    .btn-icon {
      min-width: 65px;
      min-height: 65px;

      i {
        font-size: 2.5rem;
      }
    }
  }
}
