@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--progress-value);
  }
}

// --pgPercentage {
//   syntax: '<number>';
//   inherits: false;
//   initial-value: 0;
// }

div[role='circular-progressbar'] {
  --size: 7rem;
  --fg: #894fb6;
  --bg: #f6f6f6;
  --pgPercentage: var(--progress-value);
  --pgTitle: var(--progress-title);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  background: radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
    conic-gradient($secondary calc(var(--pgPercentage) * 1%), var(--bg) 0);
  font-size: calc(var(--size) / 5);
  color: $secondary;
}

div[role='circular-progressbar']::before {
  counter-reset: percentage var(--progress-title);
  content: counter(percentage);
  white-space: pre; /* or pre-wrap */
  color: $secondary;
  font-weight: 600;
  text-align: center;
  line-height: 1;
}
div[role='circular-progressbar']::after {
  content: 'level';
  white-space: pre; /* or pre-wrap */
  color: $secondary;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}
