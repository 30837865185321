.player-widget {
  overflow: hidden;
  border: none;

  background: $primaryBgGradient;

  color: $white;

  &__info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__voice-animation {
    width: auto;
    max-height: 100px;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    color: $white;

    background: rgba(90, 48, 123, 0.7);
    backdrop-filter: blur(3px);
    z-index: 1;
  }

  &__track-rogress {
    position: absolute;
    top: -15px;
    left: 0;
    opacity: 0.5;

    transform: $transition-base;

    &:hover {
      opacity: 1;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: $white;
    font-size: 15px;

    &:not(&:last-child) {
      margin-right: 0.5rem;
    }

    &--play {
      border-radius: 50%;
      font-size: 15px;

      color: #444444;
      background-color: #fff;
      border: none;

      &:hover,
      &:focus {
        background-color: #17ab85;
        opacity: 0.9;
        border: none;
      }
    }
  }
}
