.subscription-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  max-width: 450px;
  margin: auto;

  &__inner {
    @extend .shadow;

    display: flex;
    flex-direction: column;

    flex-grow: 1;
    overflow: hidden;
    border-radius: 16px;

    border: 1px solid $gray-400;
    background-color: $white;
  }

  &--popular {
    .subscription-item__inner {
      border-color: $primary;

      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__top-label {
    text-align: center;
    color: $white;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
}
