@for $i from 8 through 32 {
  .icon-size-#{$i} {
    font-size: #{$i}px !important;
    width: #{$i}px !important;
    height: #{$i}px !important;
    line-height: #{$i}px !important;
  }
}

.fa-google {
  color: #db4437;
}

.fa-apple {
  color: #7d7d7d;
}

.fa-facebook {
  color: #4267b2;
}

.fa-twitter {
  color: #55adee;
}

.fa-instagram {
  color: #da2c75;
}

.fa-whatsapp {
  color: #3fc350;
}

.fa-viber {
  color: #8e24aa;
}

.fa-check {
  margin-right: 4px !important;
}

.fa-times {
  margin-right: 4px !important;
}

.fa-plus {
  margin-right: 4px !important;
}

.fa-music {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.fa-folder {
  margin-right: 4px !important;
}

.fa-edit {
  margin-right: 4px !important;
}

.fa-trash-can {
  margin-right: 4px !important;
}

.fa-ban {
  margin-right: 4px !important;
}

.fa-eye {
  margin-right: 4px !important;
}

.fa-refresh {
  margin-right: 4px !important;
}

.fa-cloud-arrow-down {
  margin-right: 4px !important;
}

.fa-cloud-arrow-up {
  margin-right: 4px !important;
}
