.modal {
  &-header,
  &-footer {
    border: none;
  }

  &-body {
    padding-top: 0;
  }

  &-header {
    padding-bottom: 0;
  }

  .btn-close {
    background-color: rgba($black, 0.1);
    border-radius: 50%;
    color: $black;
    opacity: 1;
  }
}

.modal-edit-profile {
  &__avatar {
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      max-width: 120px;
      max-height: 120px;
      border-radius: 50%;
      object-fit: cover;
    }
    &-edit-btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 5px;
      top: 5px;

      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $gray-200;

      &:hover {
        color: $secondary;
      }
    }
  }

  &__delete-btn {
    color: #ff0000;

    &:hover {
      color: #ff0000;
    }
  }
}

.modal-advertisement {
  .modal-content {
    background: $primaryBgGradient;
  }

  .btn-close {
    color: $white !important;
  }

  &__logo img {
    max-width: 160px;
  }

  &__women-bg {
    max-width: 75%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__border-decor {
    border-right: 1px solid rgba($white, 0.2);
  }
}

.modal-subscription {
  .modal-content {
    border: none;
  }

  &__logo {
    max-width: 120px;

    img {
      width: 100%;
      height: auto;
    }
  }
}
