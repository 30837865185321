.badges-controls {
  display: flex;
  align-items: center;

  &__title {
    color: $gray-600;
    margin: 0 0.75rem;
  }

  &__icon-button {
    @include round-icon-button(32px);
  }
}
