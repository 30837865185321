.subscription-page {
  &__col {
    padding: 0;
  }

  hr {
    color: $gray-100;
    opacity: 1;
  }

  &__submit-btn {
    max-width: 500px;
  }
}

@include media-breakpoint-up(lg) {
  .subscription-page__col {
    &--left {
      padding-right: 0.75rem;
    }

    &--right {
      padding-left: 0.75rem;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .subscription-page__col {
    &--left {
      padding-right: 1.5rem;
    }

    &--right {
      padding-left: 1.5rem;
    }
  }
}
