.tag {
  display: inline-flex;
  align-items: center;
  padding: 0.05rem 0.875rem;

  border: 1px solid $secondary;
  color: $secondary;
  font-size: 14px;

  margin-bottom: 0.3rem;

  &:not(:last-child) {
    margin-right: 0.3rem;
  }

  @extend .radius-12;

  i {
    color: $secondary;
    opacity: 0.3;
    cursor: pointer;
    margin-left: 0.35rem;
  }
}
