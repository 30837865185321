.user-page {
  &__avatar {
    width: 105px;
    height: 105px;

    overflow: hidden;
    border-radius: 50%;

    border: 1px solid $secondary;
  }
}

@include media-breakpoint-up(lg) {
  .user-page {
    &__preview-col {
      border-right: 1px solid $gray-400;
    }
  }
}
