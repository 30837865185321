.onboarding {
  &__maxh {
    max-height: 100vh;
  }

  &__maxhscroll {
    max-height: 100vh;
    overflow: scroll;
  }

  &__container {
    min-height: 100vh;
  }

  &__logo-box {
    background: $primaryBgGradient;
  }

  &__logo {
    img {
      width: 100%;
      max-width: 260px;
    }
  }

  &__card {
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius);

    img {
      width: 100%;
      height: auto;
    }
  }

  &__lock-icon-box {
    width: 80px;
    height: 80px;

    border-radius: 50%;
    background: $gray-200;
    border: 1px solid $gray-400;

    img {
      width: 55%;
      height: 55%;
    }
  }

  &__comingsoon {
    h1 {
      font-size: 48px;
    }

    i.fa-paper-plane {
      font-size: 4em;
      line-height: 1.03125em;
    }

    a { display: none; }
  }

  &__form-box {
    color: $gray-600;
    max-width: 600px;
  }

  .form-control {
    background-color: $gray-200;
    border: none;
  }

  .input-group {
    &-text {
      border: none;
    }
  }

  &__or-decor-line {
    position: relative;
    font-size: 0.875rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      height: 0.25px;
      background-color: $gray-600;

      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
}

@include media-breakpoint-up(md) {
  .onboarding {
    &__lock-icon-box {
      width: 150px;
      height: 150px;
    }
  }
}
