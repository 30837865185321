.list-group {
  &--channels {
    max-height: 500px;
    overflow-y: scroll;
    .list-group-item {
      @extend .radius-8;

      text-align: center;
      border-color: $gray-400;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .order-icons {
        display: flex;
        justify-content: space-between;
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .disabled {
        color:#ddf
      }
      i {
        margin-right: 5px;
        margin-left: 5px;
      }
      
    }
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .list-group-item + .list-group-item {
      border-top-width: 1px;
    }
    scrollbar-width: thin;
  }

  &--subscription-type {
    @extend .radius-10;

    .list-group-item {
      padding: 0.42rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;

      i {
        color: $primary;
        font-size: 1.25rem;
      }

      &:first-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:not(:first-child) {
        color: $gray-600;
      }
    }
  }
}
