.slick-track,
.slick-list {
  display: flex;
}

.slider {
  position: relative;

  margin-left: -0.75rem;
  margin-right: -0.75rem;

  .slick-slide {
    margin: 0 0.75rem;
  }

  &__control-prev,
  &__control-next {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);

    background: linear-gradient(
      144.74deg,
      #e2e2e2 10.01%,
      rgba(226, 226, 226, 0) 93.55%
    );
    border: 1px solid $gray-500;
    color: $gray-800;

    opacity: 0.7;

    transition: $transition-base;

    &:hover {
      color: $gray-800;
      opacity: 1;
    }

    .dark & {
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: linear-gradient(
        144.74deg,
        #000000 10.01%,
        rgba(0, 0, 0, 0) 93.55%
      );
      color: $white;

      &:hover {
        color: $white;
        opacity: 1;
      }
    }
  }

  &__control-prev {
    left: 5px;
    z-index: 1;
  }

  &__control-next {
    right: 5px;
  }
}

@include media-breakpoint-up(md) {
  .slider {
    &__control-prev,
    &__control-next {
      width: 60px;
      height: 60px;
    }

    &__control-prev {
      left: -20px;
    }

    &__control-next {
      right: -20px;
    }
  }
}
