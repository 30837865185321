@mixin margin-auto {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@mixin button-outline-variant(
  $color,
  $border-color: $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $border-color,
  $active-color: color-contrast($active-background)
) {
  color: $color;
  border-color: $border-color;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  .btn-check:focus + &,
  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($border-color, 0.5);
    border-color: $active-border;
    background-color: $border-color;
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow(
          $btn-active-box-shadow,
          0 0 0 $btn-focus-width rgba($border-color, 0.5)
        );
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba($border-color, 0.5);
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;
  }
}

@mixin round-icon-button($width, $height: $width, $color: $gray-600, $bgColor: $gray-200) {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  min-width: $width;
  border-radius: 50%;
  background-color: $bgColor;
  color: $color;

  cursor: pointer;

  transition: $transition-base;

  &:hover {
    background-color: rgba($color, 0.2);
  }
}
