$profileImgHeight: 40px;

.header {
  position: relative;
  border-bottom: 1px solid $gray-200;
  background-color: $white;

  height: $headerHeight;

  @extend .shadow;

  z-index: 20;

  .dark & {
    background-color: #3f0c65;
    border-color: rgba(255, 255, 255, 0.2);
  }

  &__navigation {
    &-btn {
      color: $gray-200;
    }
  }

  &__profile {
    &-logo {
      height: $profileImgHeight;
      width: $profileImgHeight;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .dark & {
      .btn-icon {
        color: $white;
      }
    }

    &-btn {
      color: $black;

      &:hover,
      &:focus {
        color: $black;
      }

      .dark & {
        color: $white;

        &:hover,
        &:focus {
          color: $white;
        }
      }
    }
  }
}
