.pagination--table {
  .page-link {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    width: 40px;
    height: 40px;

    border-radius: 50%;

    background-color: $gray-200;
    border: 1px solid rgba(161, 161, 161, 0.1);
    color: $gray-500;

    @extend .body1-bold;

    &:hover {
      background-color: transparent;
      border-color: $primary;
      background-color: rgba($primary, 0.05);
      color: $gray-900;
    }
  }

  .page-item {
    &:not(:last-child) {
      margin-right: 0.75rem;
    }

    &.dots {
      @extend .body1-bold;

      display: flex;
      justify-content: center;
      align-items: flex-end;
      color: $gray-500;

      cursor: default;
    }

    &.active .page-link {
      background-color: $primary;
      border-color: $primary;
      color: $gray-900;
    }
  }
}
