@import '../../mixins';

.badge {
  position: relative;
  margin: 0.4em 3em;
  width: 7em;
  height: 8.2em;
  border-radius: 10px;
  display: inline-block;
  top: 0;
  transition: all 0.2s ease;
  
  &.forprogress {
    width: 7em;
    height: 6.2em;
    text-align: center;
    margin: 0.4em 0.8em;
  }

  &-category-selected {
    button.back-to-category {
      font-size: 18px;
      background: none;
      border: none;
      width: auto;
      align-self: flex-start;
      margin-bottom: 1em;

      i {
        background-color: rgba(0, 0, 0, 0.1);
        width: 36px;
        height: 36px;
        display: inline-block;
        border-radius: 20px;
        line-height: 34px;
      }
    }
  }

  img.unachieved {
    filter: grayscale(100%);
  }

  &.cat {
    width: 12em;
    height: 12.2em;
    margin: 1em;

    .cattitle {
      color: #333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  &:before,
  &:after {
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: inherit;
    content: '';
    @include margin-auto;
  }
  &:before {
    transform: rotate(60deg);
  }
  &:after {
    transform: rotate(-60deg);
  }
  &:hover {
    transform: scale(1.15);
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
  }

  i.fa {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.3em;
    margin-top: 7px;

    z-index: 10;
  }
  .font {
    display: inline-block;
    margin-top: 1em;
  }
  .ribbon {
    position: absolute;
    border-radius: 4px;
    padding: 3px 4px;
    width: 100px;
    z-index: 11;
    color: #fff;
    bottom: 12px;
    left: 50%;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transform: translateX(-50%);
    font-size: 14px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    background: linear-gradient(to bottom right, #555 0%, #333 100%);
    cursor: default;
  }

  &.yellow {
    background: linear-gradient(to bottom right, #ffeb3b 0%, #fbc02d 100%);
  }

  &.orange {
    background: linear-gradient(to bottom right, #ffc107 0%, #f57c00 100%);
  }

  &.pink {
    background: linear-gradient(to bottom right, #f48fb1 0%, #d81b60 100%);
  }

  &.red {
    background: linear-gradient(to bottom right, #f4511e 0%, #b71c1c 100%);
  }

  &.purple {
    background: linear-gradient(to bottom right, #ab47bc 0%, #4527a0 100%);
  }

  &.teal {
    background: linear-gradient(to bottom right, #4db6ac 0%, #00796b 100%);
  }

  &.blue {
    background: linear-gradient(to bottom right, #4fc3f7 0%, #2196f3 100%);
  }

  &.blue-dark {
    background: linear-gradient(to bottom right, #1976d2 0%, #283593 100%);
  }

  &.green {
    background: linear-gradient(to bottom right, #cddc39 0%, #8bc34a 100%);
  }

  &.green-dark {
    background: linear-gradient(to bottom right, #4caf50 0%, #1b5e20 100%);
  }

  &.silver {
    background: linear-gradient(to bottom right, #e0e0e0 0%, #bdbdbd 100%);
  }

  &.gold {
    background: linear-gradient(to bottom right, #e6ce6a 0%, #b7892b 100%);
  }

  &.disabled {
    background: linear-gradient(to bottom right, #bfbfbf 0%, #bfbfbf 100%);
  }
}
.add-narrator-btn,
.narrator-icons {
  color: #333 !important
}