.player-page {
  &__player {
    position: relative;
    overflow: hidden;

    min-height: 320px;

    background: $primaryBgGradient;
    border-left: 1px solid $white;
  }
}

.player {
  &__panel {
    margin-top: auto;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    color: $white;

    background: rgba(90, 48, 123, 0.7);
    backdrop-filter: blur(3px);
    z-index: 1;
  }

  &__voice-animation {
    max-height: 190px;
    z-index: 2;
    opacity: 0.8;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: $white;
    font-size: 20px;

    &:not(&:last-child) {
      margin-right: 0.5rem;
    }

    &.active {
      background-color: #a05fe3;
      border-color: #a05fe3;
    }

    &--play {
      border-radius: 50%;
      font-size: 16px;

      background-color: #17ab85;
      border: none;

      &:hover,
      &:focus {
        background-color: #17ab85;
        opacity: 0.9;
        border: none;
      }
    }
  }

  .btn.btn-prev,
  .btn.btn-next,
  .btn.btn-shuffle {
    &:disabled {
      border: none;
      opacity: 0.1;
    }
  }

  &__range-input {
    background: linear-gradient(
      to right,
      $primary 0%,
      $primary 50%,
      $primary 50%,
      $primary 100%
    );
    border-radius: 8px;
    height: 5px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    accent-color: $primary;
    cursor: pointer;
    -webkit-appearance: none;
  }

  &__volume-icon {
    font-size: 16px;
  }

  &__track-rogress {
    position: absolute;
    top: -15px;
    left: 0;
    z-index: 2;
  }
}

.playlist-table {
  thead {
    border-top: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;
  }
  tbody tr {
    &:nth-child(2n + 1) {
      background-color: transparent;
    }

    &.playing {
      background-color: $gray-200;
    }

    &.inaccessible {
      opacity: 0.4;
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }

  &__course {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 10px;
    }
    .default-image-div {
      img {
        border-radius: 10px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    span {
      margin-left: 15px
    }
  }
}

.resently-played-list {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1rem 0;

    &-img {
      width: 50px;
      height: 50px;

      border-radius: 10px;
      margin-right: 1rem;
    }

    &-titles {
      @extend .text-truncate;
    }
  }
}

@include media-breakpoint-up(lg) {
  .player-page {
    &__player {
      height: 400px;
    }
  }

  .player {
    &__voice-animation {
      max-height: 320px;
      z-index: 2;
    }
  }
}
