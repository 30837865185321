.credit-card {
  display: flex;
  flex-direction: column;

  width: 360px;

  @extend .shadow;
  border-radius: 30px;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background: linear-gradient(128.29deg, #550196 27.94%, #a05fe3 143.2%);
    color: $white;
  }

  &__icon-wrapper {
    display: flex;
    justify-content: flex-end;

    i {
      transform: rotate(90deg);
    }
  }

  &__number {
    font-size: 1.2rem;
  }

  &__info {
    background-color: $white;
    color: $secondary;

    &-label {
      font-size: 0.875rem;
      opacity: 0.3;
      text-transform: uppercase;
    }

    &-value {
      @extend .font-regular;
    }
  }
}

.credit-card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__priority {
    border: 1px solid $secondary;
    color: $secondary;
    border-radius: 34px;
    padding: 0.5rem 2rem;
    text-transform: capitalize;
  }
}

@include media-breakpoint-up(md) {
  .credit-card {
    width: 400px;
  }
}
