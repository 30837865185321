.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: auto 5px;
    width: 40px;
    height: 40px;
    background-color: $gray-200;
    border: 1px solid rgba(161, 161, 161, 0.1);
    color: $gray-500;
    @extend .body1-bold;

    &:hover {
      background-color: transparent;
      border-color: $primary;
      background-color: rgba($primary, 0.05);
      color: $gray-900;
      cursor: pointer;
    }
    &.dots:hover {
      background-color: transparent;
      color: $gray-900;
    }

    &.selected {
      background-color: $primary;
      border-color: $primary;
      color: $gray-900;
    }

    &.disabled {
      pointer-events: none;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
