.search {
  position: relative;
  width: 100%;
  max-width: 350px;

  &__icon {
    position: absolute;
    left: 0.75rem;

    top: 50%;
    transform: translateY(-50%);

    z-index: 1;

    color: $gray-600;
  }

  input {
    padding-left: 40px;
  }

  input,
  input:focus {
    background-color: $gray-200;
    border: none;
  }
}
