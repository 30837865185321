.subscription-banner-medium {
  position: relative;
  display: flex;
  width: 100%;

  &__inner {
    background: linear-gradient(90deg, #550196 0%, #a05fe3 100%);
  }

  &__decor-img {
    position: absolute;
    right: -45px;
    top: -24px;
  }

  &__description {
    opacity: 0.7;
  }

  &__btn {
    max-width: 350px;
  }
}

.subscription-banner-small {
  &__decor-img {
    width: 100%;
    height: auto;
  }
  &__inner {
    background: $primaryBgGradient;
  }
}

@include media-breakpoint-up(sm) {
  .subscription-banner-medium {
    width: 93%;
    min-height: 294px;
  }
}
