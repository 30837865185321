.btn.dropdown-toggle {
  margin-top: 25px;
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &.show {
    box-shadow: none !important;
  }
}

.dropdown-menu {
  background-color: $white;
  border-color: $primary;
}

.dropdown-item {
  color: $primary;

  &:hover,
  &:focus {
    background-color: transparent;
    color: $primary;
    text-decoration: underline;
  }
}
