.drag-csv-file {
  border-radius: 30px;
  border: 1px solid $gray-400;

  &__icon {
    font-size: 90px;
  }
}

@include media-breakpoint-up(xl) {
  .drag-csv-file {
    &__icon {
      font-size: 125px;
    }
  }
}
