.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.table {
  th {
    @extend .label-1;
    color: $gray-600;
  }

  &:not(.no-striped) tbody {
    tr:nth-child(2n + 1) {
      background-color: $gray-200;
    }
  }

  th,
  td {
    border: none;
    &:first-of-type {
      padding-left: 1rem;
    }

    &:last-of-type {
      padding-right: 1rem;
    }
  }

  & > :not(caption) > * > * {
    padding: 0.75rem 0.75rem;
    vertical-align: middle;
  }

  .actions-cell {
    display: flex;

    .btn {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}

.invoice-table {
  width: 100%;
  & > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
  }
}

@include media-breakpoint-up(md) {
  .table {
    & > :not(caption) > * > * {
      padding: 1rem 0.75rem;
      min-width: 120px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .table {
    th,
    td {
      &:first-of-type {
        padding-left: 1.5rem;
      }

      &:last-of-type {
        padding-right: 1.5rem;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .table {
    th,
    td {
      &:first-of-type {
        padding-left: 3rem;
      }

      &:last-of-type {
        padding-right: 3rem;
      }
    }
  }
}
