.card {
  &.inAccessible {
    opacity: 0.4;
    pointer-events: none;
  }
  .download-btn {
    @include round-icon-button(40px, 40px, $gray-700);
  }

  &--horizontal {
    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;

      object-fit: cover;
    }
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .dark & {
    background-color: #6e25a9;

    a {
      color: #fff;
    }

    .download-btn {
      @include round-icon-button(40px, 40px, $white, rgba(0, 0, 0, 0.1));
    }
  }
}

.slick-slide{
  width: 350px;
  &:nth-child(1){
    margin-left: 0 !important;
  }
  .slider__item {
    width: 350px !important;
  }
}
.slick-track {
  margin-left: 0
}

.slider__inner {

}

.card {
  h5.card-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .card-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  div.card-img-top {
    width: 100%;
    height: 230px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .slick-slider .slick-list & {
    padding-bottom: 1px;
  }
}

a.card--select, a.card--select * {
  text-decoration: none;
  
  h4.card-title {
    color: $black;
  }

}

.dark a.card--select, .dark a.card--select * {  
  h4.card-title {
    color: $white;
  }

}

@include media-breakpoint-down(md) {
  .card {
    &--horizontal {
      img {
        border-bottom-left-radius: 0;
        border-top-right-radius: 1rem;
      }
    }
  }
}
