.content {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  height: calc(100vh - 80px);

  &-inner {
    overflow: auto;
    position: relative;
    background-color: $white;

    @extend .shadow;
    @extend .border-gray-100;
    @extend .radius-16;

    z-index: 1;
  }

  .dark & {
    background: linear-gradient(180deg, #360958 0%, #4b1972 100%);

    color: $white;
  }

  #user_level {
    background-color: #4b1972;
    color: $white;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  }
}

.headline-decor {
  position: relative;
  padding-bottom: 0.5rem;

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    max-width: 236px;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(91, 9, 157, 1),
      rgba(145, 75, 211, 0.1)
    );
  }

  .dark &::after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.1)
    );
  }
}

@include media-breakpoint-up(md) {
  .headline-decor {
    padding-right: 2rem;
  }

  .content_decor {
    &::before {
      position: fixed;
      right: -170px;
      top: -50px;
      content: "";

      width: 381px;
      height: 307px;

      // background: url("../img/top-layout-bg.svg");
      background: url("../../../img/top-layout-bg.svg");
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(-2deg);

      z-index: -1;
    }

    &::after {
      position: fixed;
      left: 113px;
      bottom: -137px;
      content: "";

      width: 367px;
      height: 296px;

      // background: url("../img/bottom-layout-bg.svg");
      background: url("./../../../img/bottom-layout-bg.svg");
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(-2deg);

      z-index: -1;
    }
  }
}
