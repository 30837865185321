a {
  color: $primary;
  transition: $nav-link-transition;

  &:hover {
    color: $green-600;
    text-decoration: none;
    cursor: pointer;
  }
}
