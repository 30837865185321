.btn {
  @extend .font-medium;
}

a.btn {
  text-decoration: none;
}

.btn-sm {
  @extend .radius-8;
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn-lg {
  @extend .radius-10;

  padding-left: 1rem;
  padding-right: 1rem;
}

.btn-icon {
  @extend .btn;
  @extend .btn-link;

  display: flex;
  align-items: center;
  justify-self: center;

  padding-left: 0;
  padding-right: 0;
  text-decoration: none;

  &.btn-primary,
  &.btn-secondary,
  &.btn-danger {
    padding: 0.25rem 0.25rem;

    color: $white;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  color: $gray-600;

  &:hover,
  &:focus {
    color: $gray-600;
  }

  &-round {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;

    line-height: 0;

    border-radius: 50%;
    padding: 0;
    background-color: transparent !important;
    border: 1px solid #e1e1e2;

    &:hover,
    &:focus {
      background-color: $gray-300;
    }

    i {
      font-size: 1.5rem;
    }
  }
}

.btn-outline-primary,
.btn-outline-secondary {
  background-color: $white;
}
