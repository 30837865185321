.nav--side-menu {
  .nav-item {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  .nav-link {
    &:hover,
    &:focus {
      color: $primary;
      text-decoration: none;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    background-color: $primary !important;
    color: $gray-900 !important;
    @extend .font-medium;
  }

  @include media-breakpoint-down(md) {
    .nav-link {
      padding: 0.5rem;
    }
  }
}

.nav-tabs {
  position: relative;
  border-bottom: $gray-300 solid 2px;

  .nav-item {
    position: relative;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 1rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:not(:last-child)::after {
      position: absolute;
      content: '';
      right: -1px;
      top: 50%;
      transform: translateY(-50%);
      height: 75%;
      width: 2px;
      background-color: $gray-300;
    }
  }

  .nav-link {
    position: relative;
    border: none;
    border-radius: 8px;

    color: $gray-900;

    @extend .font-regular;

    &:hover {
      text-decoration: none;
      color: $secondary;
    }

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      background-color: $secondary;
      bottom: calc(-1rem + -1px);
      left: 0;

      display: none;
    }
  }

  .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: $secondary;

    &::after {
      display: block;
    }
  }
}
