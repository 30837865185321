.drag-file {
  border: 1px solid #e0e9f0;

  @extend .radius-8;

  &__description {
    color: #7f8fa4;
    font-size: 0.875rem;
    max-width: 420px;
    margin: 0 auto;
  }

  i {
    font-size: 2.5rem;
    color: #7f8fa4;

    &:first-child {
      margin-top: 15px;
      margin-right: 1rem;
      transform: rotate(-15deg);
    }

    &:last-child {
      margin-top: 15px;
      margin-left: 0.5rem;
      transform: rotate(15deg);
    }
  }
}

@include media-breakpoint-up(xl) {
  .drag-file {
    i {
      font-size: 3.75rem;
    }
  }
}
