.credit-cards-container {
  position: relative;
  overflow: hidden;

  .card-wrap {
    min-height: 378px;
  }

  &::after {
    position: fixed;
    content: "";

    height: 100%;
    right: 0;
    top: 0;
    width: 300px;

    background: linear-gradient(
      269.72deg,
      #ffffff 0.28%,
      rgba(255, 255, 255, 0) 95.59%
    );

    pointer-events: none;
  }

  .add-credit-card {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;

    transform: translateY(-50%);
  }
}
