@keyframes narratorAnimationIn {
  0% {
    transform: translateX(42px) scale(0.6);
    transform-origin: 0px 42px;
  }

  100% {
    transform: translateX(0px) scale(1);
    transform-origin: 0px 0px;
  }
}

.animateIn {
  animation: 0.7s ease-in-out 0s 1 narratorAnimationIn alternate;
}

@keyframes narratorAnimationOut {
  0% {
    transform: translateX(-42px) scale(1.4);
    transform-origin: 0px 42px;
  }

  100% {
    transform: translateX(0px) scale(1);
  }
}

.animateOut {
  animation: 0.7s ease-in-out 0s 1 narratorAnimationOut alternate;
}

@keyframes narratorAnimationInLong {
  0% {
    transform: translateX(65px) scale(0.6);
    transform-origin: 0px 38px;
  }

  100% {
    transform: translateX(0px) scale(1);
    transform-origin: 0px 0px;
  }
}

.animateInLong {
  animation: 0.7s ease-in-out 0s 1 narratorAnimationInLong alternate;
}

@keyframes narratorAnimationOutLong {
  0% {
    transform: translateX(-73px) scale(1.4);
    transform-origin: 0px 45px;
  }

  100% {
    transform: translateX(0px) scale(1);
  }
}

.animateOutLong {
  animation: 0.7s ease-in-out 0s 1 narratorAnimationOutLong alternate;
}
