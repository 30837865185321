@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900");

.font-light {
  font-weight: 300 !important;
}
.font-regular {
  font-weight: 400 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-semi-bold {
  font-weight: 600 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.font-extra-bold {
  font-weight: 800 !important;
}

$font-family: "poppins", sans-serif !important;

.body1-regular {
  font-family: $font-family;
  font-weight: 300;
}

.body1-bold {
  font-family: $font-family;
  font-weight: 600;
}

.label-1 {
  font-family: $font-family;
  font-weight: 300;
}

.label-regular-1 {
  font-family: $font-family;
  font-weight: 500;
}

.label-bold-1 {
  font-family: $font-family;
  font-weight: 600;
}

.radius-4 {
  border-radius: 4px !important;
}

.radius-6 {
  border-radius: 6px !important;
}

.radius-8 {
  border-radius: 8px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-12 {
  border-radius: 12px !important;
}

.radius-16 {
  border-radius: 16px !important;
}

.radius-50 {
  border-radius: 50% !important;
}
