.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  overflow: hidden;

  width: 40px;
  height: 40px;
  background-color: #9a66c3;
  color: $white;

  border-radius: 50%;

  &.inverse {
    background-color: #f8f0ff;
    color: $secondary;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
