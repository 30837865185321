.scrolling {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden !important;
  .list-group-item {
    @extend .radius-8;

    text-align: center;
    border-color: $gray-400;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .list-group-item + .list-group-item {
    border-top-width: 1px;
  }
  scrollbar-width: thin;
}
