$colors: map-merge($colors, $theme-colors);
$colors: map-merge($colors, $blues);
$colors: map-merge($colors, $indigos);
$colors: map-merge($colors, $purples);
$colors: map-merge($colors, $reds);
$colors: map-merge($colors, $oranges);
$colors: map-merge($colors, $yellows);
$colors: map-merge($colors, $greens);
$colors: map-merge($colors, $teals);
$colors: map-merge($colors, $cyans);
$colors: map-merge(
  $colors,
  (
    'gray-100': $gray-100,
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'gray-800': $gray-800,
    'gray-900': $gray-900,
  )
);

@each $name, $value in $colors {
  .color-#{$name} {
    color: var(--#{$name});
  }

  .bg-color-#{$name} {
    background-color: var(--#{$name}) !important;
  }
  .border-#{$name} {
    border: 1px solid var(--#{$name});

    .dark & {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
  .border-top-#{$name} {
    border-top: 1px solid var(--#{$name});

    .dark & {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
  .border-bottom-#{$name} {
    border-bottom: 1px solid var(--#{$name});

    .dark & {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
  .border-left-#{$name} {
    border-left: 1px solid var(--#{$name});

    .dark & {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
  .border-right-#{$name} {
    border-right: 1px solid var(--#{$name});

    .dark & {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:visited {
  @extend .color-green;
}

.btn-link-danger,
.btn-link-danger:hover,
.btn-link-danger:active,
.btn-link-danger:visited {
  @extend .color-red;
}

.btn-link-danger:hover {
  background-color: red;
  color: whitesmoke;
}

.red:hover {
  background-color: red;
}

.btn-primary {
  @include button-variant(
    $primary,
    $primary,
    $gray-900,
    darken($primary, 10%),
    darken($primary, 10%),
    $gray-900,
    darken($primary, 10%),
    darken($primary, 10%),
    $gray-900,
    $green-300,
    $green-300,
    $gray-900
  );
}

.btn-outline-primary {
  @include button-outline-variant(
    $gray-900,
    $primary,
    $gray-900,
    darken($primary, 10%),
    darken($primary, 10%),
    $gray-900
  );
}

.btn-light {
  @include button-variant(
    #e6e6e6,
    #e6e6e6,
    $gray-900,
    darken(#e6e6e6, 10%),
    darken(#e6e6e6, 10%),
    $gray-900,
    darken(#e6e6e6, 10%),
    darken(#e6e6e6, 10%),
    $gray-900,
    $gray-200,
    $gray-200,
    $gray-900
  );
}

:root {
  @each $name, $value in $colors {
    --#{$name}: #{$value};
  }
}
