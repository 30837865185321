.subscribe-landing {
    background: linear-gradient(45deg, #813dc8, #550195);

    .title-with-decor {
        span {
            position: relative;

            &::after {
                position: absolute;
                content: '';
                left: 0;
                right: 0;
                width: 100%;
                height: 1px;
                bottom: -7px;
                background-color: #74f2a4;
            }
        }
    }

    .green-line-decor {
        display: block;
        margin: 0 auto;
        width: 100px;
        height: 1px;
        background-color: #74f2a4;
    }

    .price-item {
        position: relative;
        width: 250px;
        height: 250px;

        cursor: pointer;

        &__best-value {
            position: absolute;
            background-color: #74f2a4;
            text-align: center;
            top: 0;
            left: 50%;

            transform: translate(-50%, -50%);
            width: 85%;
            border-radius: 10px;
            box-shadow: 0 6px 6px rgba(0, 0, 0, 0.27);
            pointer-events: none;
        }

        &__inner {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            border: 1px solid #000;
            background-color: #7c37b8;

            &.selected {
                background-color: #9c2fbe;
                border-color: #74f2a4;
            }
        }

        &__bottom {
            color: #7b34b5;
        }
    }

    .fa-regular.fa-square-check.fa-lg {
        color: #74f2a4
    }

    .join-card {
        font-size: 18px;
    }

    .trial-works {
        background: linear-gradient(45deg, #550195, #813dc8);
    }

    .trial-works-list {

        &__item {
            position: relative;
            display: flex;
            align-items: center;

            margin-bottom: 40px;

            &:first-child {
                .trial-works-list__icon {
                    background-color: #74f2a4;

                    &::after {
                        display: none;
                    }
                }
            }
        }

        &__icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            margin-right: 10px;

            border-radius: 50%;
            background: white;
            color: #550195;

            z-index: 1;

            &::after {
                position: absolute;
                content: '';
                left: 50%;
                height: 66px;
                width: 1px;
                bottom: 100%;
                background-color: white;
                z-index: 0;
            }
        }
    }

    .try-7-free {
        background-color: #320059;
    }

    .comments {
        .title-with-decor {
            color: #550195;
        }


        &__slider {
            max-width: 700px;
            margin: 0 auto;

            .slick-slide {
                width: 700px !important;
            }

            &-item {
                border: 1px solid #550195;
                border-radius: 10px;

                &-stars {
                    color: #e0d717;
                }
            }

            .slick-dots {
                display: flex !important;
                padding: 0;
                margin: 0;
                list-style: none;
                align-items: center;
                justify-content: center;
                margin-top: 10px;

                li {
                    &:not(:first-child) {
                        margin-left: 10px;
                    }

                    button {
                        background: none;
                        width: 12px;
                        height: 12px;
                        padding: 0;

                        background-color: transparent;
                        border: 1px solid #550195;
                        border-radius: 50%;
                        font-size: 0;

                    }

                    &.slick-active {
                        button {
                            background-color: #550195;
                        }
                    }
                }
            }
        }
    }

    .faq {
        background-color: #431367;


        &__accordion {
            width: 575px;
            background-color: transparent;

            .accordion-item {
                background-color: transparent;
                border-left: none;
                border-right: none;
                border-radius: 0;
                border-color: #4a1d6e;
            }

            .accordion-body {
                color: white;
            }

            .accordion-button {
                background-color: transparent;
                color: white;
                font-weight: 600;

                &::after {
                    display: none;
                }

                .icon {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    background-color: #74f2a4;
                    color: #550195;
                    border-radius: 50%;
                    margin-right: 10px;
                    font-size: 14px;

                }

                i {
                    display: none;
                }

                &.collapsed i.collapsed {
                    display: block;
                }

                &:not(.collapsed) i:not(.collapsed) {
                    display: block;
                }
            }

        }
    }
}